import React, { useState } from "react";
import { observer } from "mobx-react";
import { makeApi, URLS } from "Utils/apiURL";
import Button from "Components/common/Button";
import Utils from "Utils/utils";
import { toasterState } from "Components/common/Toaster";
import StandardInputTextBox from "Components/common/StandardInputTextBox";
import { settingsPopupState } from "./SettingsPopup";

const options = [
  { label: "true", value: "true" },
  { label: "false", value: "false" },
];

const featureFlagConfig = [
  {
    label: "AB Testing Enabled",
    value: "abTestingEnabled",
    options,
  },
  {
    label: "Branded Url Enabled",
    value: "brandedUrlEnabled",
    options,
  },
  {
    label: "Crm Auto Bcc Enabled",
    value: "crmAutoBccEnabled",
    options,
  },
  {
    label: "Dialer Enabled",
    value: "dialerEnabled",
    options,
  },
  { label: "Export Enabled", value: "exportEnabled", options },
  {
    label: "Multiple Inbox Enabled",
    value: "multipleInboxEnabled",
    options,
  },
  {
    label: "Task Enabled",
    value: "taskEnabled",
    options,
  },
  {
    label: "Triggers Enabled",
    value: "triggersEnabled",
    options,
  },
  {
    label: "Writing Assistant Enabled",
    value: "writingAssistantEnabled",
    options,
  },
  {
    label: "Public API Access",
    value: "publicApiEnabled",
    options,
  },{
    label: "LinkedIn Enabled",
    value: "liAutomationEnabled",
    options,
  }
];

const crmRelatedFlags = [
  {
    label: "Salesforce Enabled",
    value: "salesforceIntegrationEnabled",
    options,
  },
  {
    label: "Hubspot Enabled",
    value: "hubspotIntegrationEnabled",
    options,
  },
  {
    label: "Pipedrive Enabled",
    value: "pipedriveIntegrationEnabled",
    options,
  },
  {
    label: "Slack Enabled",
    value: "slackIntegrationEnabled",
    options,
  },
  {
    label: "Zapier Enabled",
    value: "zapierIntegrationEnabled",
    options,
  },
  {
    label: "Zoho Enabled",
    value: "zohoIntegrationEnabled",
    options,
  },
];

const featureFlagInputConfig = [
  {
    label: "Dialer credits",
    value: "dialerCredits",
    type: "number",
    className: "dialerCreditsInputBox",
    step: "any",
  },
  {
    label: "No of inboxes eligible",
    value: "noOfInboxesEligible",
    type: "number",
    className: "noOfInboxesEligibleInputBox",
  },
  {
    label: "No of sequences eligible",
    value: "noOfSequencesEligible",
    type: "number",
    className: "noOfSequencesEligibleInputBox",
  },
  {
    label: "No of Templates eligible",
    value: "noOfTemplatesEligible",
    type: "number",
    className: "noOfTemplatesEligibleInputBox",
  },
  {
    label: "Email Send limit",
    value: "emailSendLimit",
    type: "number",
  },
  {
    label: "Contact limit",
    value: "noOfContactsEligible",
    type: "number",
  },
  {
    label: "Custom Field Limit",
    value: "customFieldsCount",
    type: "number",
  },
  {
    label: "Email Validation Credits",
    value: "emailValidationCredits",
    type: "number",
  },
  {
    label: "Warming Accounts Eligible",
    value: "warmingAccountsEligible",
    type: "number",
  },{
    label: "LinkedIn Accounts Eligible",
    value: "noOfLiAccountsEligible",
    type: "number",
  }
];

const UpdateFeatureFlagConfig=[
  {
    label: "Domain Health Center Enabled",
    value: "domainHealthCenterEnabled",
    options,
  },
  {
    label: "Website Visitor Tracking Enabled",
    value: "websiteVisitorTrackingEnabled",
    options,
  },
  {
    label: "Contact Stages Enabled",
    value: "contactStagesEnabled",
    options,
  },
  {
    label: "Do not Contact Enabled",
    value: "donotContactEnabled",
    options,
  },
  {
    label: "Calendar Integration Enabled",
    value: "calendarIntegrationEnabled",
    options,
  },
  {
    label: "Email Validation Enabled",
    value: "emailValidationEnabled",
    options,
  },
  {
    label: "Custom Fields Enabled",
    value: "customFieldsEnabled",
    options,
  },
]

const UpdateUserFeatureFlag = observer((props) => {
  const {
    email = "",
    activeLabelList = {},
    setActiveLabelList = () => {},
    constructTeamData = () => {},
  } = props;
  const [buttonLoader, setButtonLoader] = useState(false);

  const handleChange = (value = "", event) => {
    setActiveLabelList({
      ...activeLabelList,
      [value]: event?.currentTarget?.value === "true",
    });
  };

  const handleNumberBoxChange = (label = "", value) => {
    setActiveLabelList({
      ...activeLabelList,
      [label]: Number(value),
    });
  };

  const updateUserFeatureFlag = async (data = {}, email = "") => {
    if (email) {
      const config = {
        url: URLS.updateTeamInfo,
        method: "POST",
        data: { team: data, email: email },
      };
      let response = await makeApi(config);
      if (response?.data) {
        setButtonLoader(false);
        settingsPopupState.setShowPopup(false);
        toasterState.setToastMsg(
          "User Feature flag updated successfully",
          "success"
        );
        const constructedTeamData = constructTeamData(response?.data?.team);
        setActiveLabelList({ ...constructedTeamData });
      } else {
        Utils.showApiFailureResponse(response);
      }
    } else {
      setButtonLoader(false);
    }
  };

  const handleUpdateFeatureFlag = (e) => {
    e.preventDefault();
    setButtonLoader(true);
    updateUserFeatureFlag(activeLabelList, email);
  };

  const updatedFeatureFlagList = [...featureFlagConfig, ...crmRelatedFlags,...UpdateFeatureFlagConfig];

  return (
    <form
      className="updateUserFeatureFlagWrapper"
      onSubmit={handleUpdateFeatureFlag}
    >
      <div className="userFeatureFlagCont">
        {updatedFeatureFlagList?.length > 0 &&
          updatedFeatureFlagList?.map((item) => {
            const { label = "", value: itemValue = "" } = item || {};
            return (
              <div className="dataContainer" key={itemValue}>
                <div key={itemValue}>{label}</div>
                <select
                  value={activeLabelList[itemValue]}
                  onChange={(e) => handleChange(itemValue, e)}
                >
                  {item?.options?.map((option) => {
                    const { value = "" } = option || {};
                    return (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    );
                  })}
                </select>
              </div>
            );
          })}
        {featureFlagInputConfig.map((item) => {
          const {
            label = "",
            value = "",
            type = "",
            className = "",
            step = "",
          } = item;
          return (
            <div className="dataContainer">
              <div key={value}>{label}</div>
              <StandardInputTextBox
                name={value}
                id={value}
                type={type}
                className={className}
                value={activeLabelList?.[value]}
                cbk={handleNumberBoxChange}
                step={step}
              />
            </div>
          );
        })}
      </div>
      <div className="submitButton">
        <Button
          id="userDetails"
          name="userDetails"
          type="submit"
          className={`primaryButton ${buttonLoader ? "loading" : ""}`}
          btnText="Submit"
          icon={false}
          loader={buttonLoader}
          iconName="keyboard_arrow_down"
        />
      </div>
    </form>
  );
});

export { UpdateUserFeatureFlag };
export default UpdateUserFeatureFlag;
